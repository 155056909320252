* {
  box-sizing: border-box;
}

body {
  color: #242321;
}

h1 {
  margin: 0 0 0.5rem;
  font-size: 1.5rem;
}

button {
  outline: none;
  cursor: pointer;
}

li,
dd {
  margin-bottom: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0.5rem 0 1.5rem 0;
}

img {
  border-radius: 5px;
}


.App {
  display: flex;
  height: 100%;
  /* min-height: 100%; */
}

.ContentBlock {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
  background-color: #ccc;
}

.Nav ul {
  margin-top: 0;
  margin-left: 1.5rem;
  padding-top: 0.75rem;
  padding-left: 1.1rem;
  padding-right: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.Nav li {
  margin-bottom: 1rem;
}

.App.NavOpen .Header .NavMenuIcon {
  visibility: hidden;
}

.App.NavClosed .Nav {
  transform: translateX(0);
}

.App.NavOpen .Nav {
  transform: translateX(100%);
}

.Header {
  display: flex;
  flex-direction: column;
  min-height: 5rem;
  max-height: 5rem;
  overflow: hidden;
  background-color: #666666;
  padding: 0 0.5rem 0.5rem 0;
  font-size: 1rem;
}

.HeaderMain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.HomeLink {
  font-size: 1.25rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.HeaderText {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
  margin-left: 0.5rem;
  line-height: 1;
  font-size: 0.825rem;
  color: #ccc;
}

.HeaderText > * {
  /* display: block; */
  white-space: pre-wrap;
}

.Nav {
  position: fixed;
  left: -100%;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transition: transform 0.15s ease-out;
  background-color: #ffcc00;
}

.Nav a,
.Nav a:active,
.Nav a:visited {
  color: #242321;
  text-decoration: none;
}

.Nav a.HomeLink,
.Nav a.HomeLink:active,
.Nav a.HomeLink:visited {
  color: #fff;
}

.NavMenuIconWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
}

.NavMenuIconWrapper .HomeLink {
  padding-left: 1.5rem;
}

.NavMenuIcon {
  padding: 0.5rem 0.75rem;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  line-height: 0.8;
}

.MainContent {
  max-width: 100%;
  overflow: auto;
}

.MainContent a,
.MainContent a:active,
.MainContent a:visited {
  color: blue;
  border-bottom: 1px solid blue;
  text-decoration: none;
}

.Content {
  width: 100%;
  max-width: 43.75rem;
  padding: 1rem;
  margin: auto;
}

.CookiesInfo {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  transform: translateY(0);
  transition: transform 0.15s ease-out;
  color: #fff;
  background-color: #000;
  font-size: 0.9rem;
}

.CookiesInfo button {
  border: 1px solid #fff;
  border-radius: 3px;
  background-color: #fff;
}

.App.CookiesInfoClosed + .CookiesInfo {
  transform: translateY(100%);
}

.ScrollToTop {
  margin-top: 1rem;
  border-radius: 4px;
  border: 1px solid #666;
}

.ScrollToTop .Icon {
  font-size: 1.25rem;
  vertical-align: sub;
}

.RightFloatImage {
  display: block;
  max-width: 100%;
  margin: 1rem auto 2rem;
}

.Important {
  font-weight: bold;
}

@media (min-width: 800px) {
  .Nav {
    position: static;
    flex-shrink: 0;
    width: 30%;
    /* width: 170px; */
    height: auto;
  }
  .Nav ul {
    max-width: 15.625rem;
    margin-top: 5rem;
    margin-left: auto;
    padding-left: 1.5rem;
    padding-right: 2em;
    direction: rtl;
  }
  .Nav ul li a {
    direction: ltr;
    unicode-bidi: embed;
  }
  .App.NavClosed .Nav,
  .App.NavOpen .Nav {
    transform: none;
  }
  .NavMenuIconWrapper,
  .NavMenuIcon {
    display: none;
  }
  .HomeLink {
    margin-left: 0.5rem;
  }
  .ContentBlock {
    width: 70%;
    /* margin-left: 175px; */
  }
  .Header {
    padding: 1rem 1.5rem 0.5rem;
  }
  .MainContent {
    /* height: 100%; */
    overflow: auto;
  }
  .Content {
    padding: 1rem 2rem;
    margin: unset;
  }
  .RightFloatImage {
    float: right;
    margin-left: 1rem;
    margin-right: 0;
  }
}
