.Quote {
  /* background: #f9f9f9; */
  border-left: 3px solid #666;
  margin: 1.5rem 0.75rem 0.5rem 0;
  padding: 0.5rem 0.75rem;
  quotes: "“" "„";
}

.Quote:before {
  color: #666;
  content: open-quote;
  font-size: 3em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.Quote p {
  display: inline;
}

.Quote footer {
  margin-top: 0.5rem;
}
