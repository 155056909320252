.HomeWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.HomeIntro {
  margin-bottom: 2rem;
}

.HomeWrapper aside {
    flex-shrink: 0;
    align-self: center;
    width: 300px;
    max-width: 100%;
}

.HomeWarning {
    padding: 0.5rem;
    border: 1px solid #666;
    border-radius: 3px;
}

.HomeWarningIcon {
    float: left;
    animation: blinkingWarningIcon 1.2s infinite;
}

.HomeWarningText {
    margin-top: 0;
    margin-bottom: 0.5rem;
    margin-left: 1.5em;
}

.HomeWarningLinkWrapper {
  display: inline-block;
  margin-top: 0.5rem;
}

@media (min-width: 600px) {
    .HomeWrapper {
        flex-direction: row;
    }

    .HomeIntro {
        padding-right: 2rem;
    }
}

@keyframes blinkingWarningIcon {
    0% {
      color: inherit;
    }
    49% {
      color: inherit;
    }
    60% {
      color: transparent;
    }
    99% {
      color: transparent;
    }
    100% {
      color: inherit;
    }
  }